<template>
  <h1
    class="title"
    tabindex="-1"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'CTitle'
}
</script>

<style lang="scss" scoped>
.title {
  outline: 0;
}
</style>
